import React from "react"
import RootLayout from "./src/components/layouts/Root-layout"
import ReactDOM from "react-dom"

export const wrapPageElement = ({ element, props }) => {
  return <>{element}</>
}

// Wrap the theme
export const wrapRootElement = ({ element }) => {
  return <RootLayout>{element}</RootLayout>
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
