import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import createPalette, {
  PaletteOptions,
} from "@material-ui/core/styles/createPalette"

export const headingFontFamily = "Rajdhani"
export const bodyFontFamily = "Rajdhani"
export const bps = createBreakpoints({})

const palette: PaletteOptions = {
  text: {
    primary: "rgba(0,0,0,.87)",
    secondary: "rgba(255,255,255,0.6)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.8)",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  common: {
    black: "#000",
    white: "#fff",
  },
}

export const typography = {
  useNextVariants: true,
  // xsmallFontSize: 12,
  // smallFontSize: 14,
  // fontSize: 16,
  // htmlFontSize: 10,
  fontFamily: headingFontFamily,
  h1: {
    fontFamily: headingFontFamily,
    fontSize: 46,
    fontWeight: 700,
  },
  h2: {
    fontFamily: headingFontFamily,
    fontSize: 32,
    fontWeight: 700,
  },
  h3: {
    fontFamily: headingFontFamily,
    fontSize: 24,
    fontWeight: 600,
  },
  h4: {
    fontFamily: headingFontFamily,
    fontSize: 22,
    fontWeight: 500,
  },
  h5: {
    fontFamily: bodyFontFamily,
    fontSize: 20,
    fontWeight: 500,
  },
  h6: {
    fontFamily: bodyFontFamily,
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  subtitle1: {
    fontFamily: bodyFontFamily,
    fontSize: 20,
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: bodyFontFamily,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 400,
  },
  body1: {
    fontFamily: bodyFontFamily,
    fontSize: 18,
  },
  body2: {
    fontFamily: bodyFontFamily,
    fontSize: 14,
    letterSpacing: 0.25,
  },
  caption: {
    fontFamily: bodyFontFamily,
    fontSize: 18,
    fontWeight: 700,
  },
  overline: {
    fontFamily: bodyFontFamily,
    fontSize: 10,
    letterSpacing: "1.4px",
    lineHeight: "16px",
  },
  button: {
    fontFamily: bodyFontFamily,
    fontSize: 18,
    fontWeight: 600,
  },
}

let mainTheme = createMuiTheme({
  typography,
  palette: createPalette(palette),
})

mainTheme = responsiveFontSizes(mainTheme)

export default mainTheme
