import React from "react"
import { ResetStyle, GlobalStyle } from "./globalStyle"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles/"
import { ThemeProvider } from "styled-components"
import mainTheme from "../../theme/index"

const ThemeProviderWrapper = ({ children }: any) => {
  return (
    <MuiThemeProvider theme={mainTheme}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default ({ children }: any) => {
  return (
    <>
      <ResetStyle />
      <GlobalStyle />
      <ThemeProviderWrapper>{children}</ThemeProviderWrapper>
    </>
  )
}
