exports.components = {
  "component---src-components-templates-daily-insight-details-tsx": () => import("./../../../src/components/templates/daily-insight-details.tsx" /* webpackChunkName: "component---src-components-templates-daily-insight-details-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-buying-and-selling-stocks-tsx": () => import("./../../../src/pages/about/buying-and-selling-stocks.tsx" /* webpackChunkName: "component---src-pages-about-buying-and-selling-stocks-tsx" */),
  "component---src-pages-about-depositing-sixer-coins-tsx": () => import("./../../../src/pages/about/depositing-sixer-coins.tsx" /* webpackChunkName: "component---src-pages-about-depositing-sixer-coins-tsx" */),
  "component---src-pages-about-free-coins-and-stocks-tsx": () => import("./../../../src/pages/about/free-coins-and-stocks.tsx" /* webpackChunkName: "component---src-pages-about-free-coins-and-stocks-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-payment-details-tsx": () => import("./../../../src/pages/about/payment-details.tsx" /* webpackChunkName: "component---src-pages-about-payment-details-tsx" */),
  "component---src-pages-about-real-market-portfolio-requirements-tsx": () => import("./../../../src/pages/about/real-market-portfolio-requirements.tsx" /* webpackChunkName: "component---src-pages-about-real-market-portfolio-requirements-tsx" */),
  "component---src-pages-about-withdrawing-sixer-coins-tsx": () => import("./../../../src/pages/about/withdrawing-sixer-coins.tsx" /* webpackChunkName: "component---src-pages-about-withdrawing-sixer-coins-tsx" */),
  "component---src-pages-amazon-pay-acc-link-tsx": () => import("./../../../src/pages/amazon_pay_acc_link.tsx" /* webpackChunkName: "component---src-pages-amazon-pay-acc-link-tsx" */),
  "component---src-pages-amazon-pay-acc-pay-tsx": () => import("./../../../src/pages/amazon_pay_acc_pay.tsx" /* webpackChunkName: "component---src-pages-amazon-pay-acc-pay-tsx" */),
  "component---src-pages-ambassador-tsx": () => import("./../../../src/pages/ambassador.tsx" /* webpackChunkName: "component---src-pages-ambassador-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-d-11-download-tsx": () => import("./../../../src/pages/d11/download.tsx" /* webpackChunkName: "component---src-pages-d-11-download-tsx" */),
  "component---src-pages-d-11-index-tsx": () => import("./../../../src/pages/d11/index.tsx" /* webpackChunkName: "component---src-pages-d-11-index-tsx" */),
  "component---src-pages-daily-insights-tsx": () => import("./../../../src/pages/daily-insights.tsx" /* webpackChunkName: "component---src-pages-daily-insights-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-legality-tsx": () => import("./../../../src/pages/legality.tsx" /* webpackChunkName: "component---src-pages-legality-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

